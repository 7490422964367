<template>
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="tab-sort-box">
        <span class="tab-btn-group">
          <a class="tab-btn " :class="tab ? 'on' : ''" @click="tab = true">
            {{ $t('introduction.tab1') }}
          </a>
          <a class="tab-btn" :class="!tab ? 'on' : ''" @click="tab = false">
            {{ $t('introduction.tab2') }}
          </a>
        </span>
      </div>
      <!--/tab-sort-box-->
      <div class="tab-content current" v-if="tab">
        <div class="introduce-box ser01">
          <p class="t02">
            {{ $t('introduction.tab1-eng') }}
          </p>
          <h5 class="t01">
            {{ $t('introduction.tab1-title1') }}
          </h5>
          <figure class="plat-figure" v-if="$i18n.locale === 'ko'">
            <img
              src="@/assets/libs/images/intro_s1.png"
              alt="Service Platform"
            />
          </figure>
          <figure class="plat-figure" v-if="$i18n.locale === 'en'">
            <img
              src="@/assets/libs/images/intro_s1_eng.png"
              alt="Service Platform"
            />
          </figure>
          <p class="t04" v-html="$t('introduction.tab1-content')"></p>
          <h5 class="t01">{{ $t('introduction.tab1-title2') }}</h5>
          <dl class="plat-dl" v-if="$i18n.locale === 'ko'">
            <dt></dt>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_01.png"
                alt="Heath Management"
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_02.png"
                alt="건강관리앱"
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_03.png"
                alt="Remote diagnosis"
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_04.png"
                alt="Exercise prescription "
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_05.png"
                alt="Health status measurement "
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_06.png"
                alt="Health information"
              />
            </dd>
          </dl>
          <dl class="plat-dl" v-if="$i18n.locale === 'en'">
            <dt></dt>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_01_eng.png"
                alt="Heath Management"
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_02_eng.png"
                alt="건강관리앱"
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_03_eng.png"
                alt="Remote diagnosis"
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_04_eng.png"
                alt="Exercise prescription "
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_05_eng.png"
                alt="Health status measurement "
              />
            </dd>
            <dd>
              <img
                src="@/assets/libs/images/intro_s1_06_eng.png"
                alt="Health information"
              />
            </dd>
          </dl>
        </div>
      </div>
      <!--/#service01-->
      <div class="tab-content current" v-else>
        <div class="introduce-box ser02">
          <h5 class="t01" v-html="$t('introduction.tab2-title')"></h5>
          <p class="t03" v-html="$t('introduction.tab2-content')"></p>
          <dl class="sup-dl">
            <dt></dt>
            <dd></dd>
            <dd></dd>
            <dd></dd>
            <dd></dd>
          </dl>
        </div>
      </div>
    </div>
    <!--/contents-->
  </div>
  <!--/container-->
</template>
<script>
export default {
  data() {
    return {
      tab: true,
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.about'),
        this.$i18n.t('subMenu.introdution'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.about'),
        smallTitle: this.$i18n.t('menu.about-eng'),
      },
    };
  },
};
</script>
<style lang=""></style>
